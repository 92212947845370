import { ChartConfiguration } from 'chart.js';
import {
  Card,
  Badge,
  Select,
  Space,
  Group,
  Text,
  Divider,
  createStyles,
} from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import { Chart, NumericText } from 'src/components';
import { theme } from 'src/styling/theme';
import { PercentFormat, SOCIAL_PLATFORMS } from 'src/utils/constants';
import { RootState } from 'src/redux/reducers';
import { getDateIntervalLabel } from 'src/utils/date';
import {
  DatePreset,
  HistogramInterval,
  PlatformStats,
  PlatformTypeLabels,
} from 'src/types';
import { setAretoScoreFilter } from 'src/redux/reducers';
import { calculatePercentageChange } from 'src/utils/formulas';

const useStyles = createStyles(theme => ({
  aretoScoreCard: {
    height: '100%',
  },
  breakdownPercentPositiveChange: {
    background: 'rgba(92, 224, 217, 0.08)',
    color: '#5CE0D9',
    fontSize: 14,
    paddingBottom: 7,
    borderRadius: 8,
    border: 0,
  },
  breakdownPercentNegativeChange: {
    background: 'rgba(255, 203, 0, 0.08)',
    color: '#FFCB00',
    fontSize: 14,
    paddingBottom: 7,
    borderRadius: 8,
    border: 0,
  },
}));

// TODO: link ChartColor to platform
const ChartColors = ['#C19A0C', '#FFCB00', '#f4d152', '#f9e499'];

export const AretoScoreChartCard = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [aretoScoreChange, setAretoScoreChange] = useState<number>(0);
  const dashboard = useSelector((state: RootState) => state.dashboard);
  const trackedPlatforms = useSelector(
    (state: RootState) => state.user.trackedPlatforms
  );

  const TRACKED_PLATFORMS =
    trackedPlatforms && trackedPlatforms.length > 0
      ? trackedPlatforms
      : SOCIAL_PLATFORMS;

  const _getAretoScore = () => {
    let aretoScore = 1;

    if (dashboard.aretoScoreFilter === 'ALL')
      aretoScore = dashboard.accountStats!.current.aretoScore;
    else
      dashboard.accountStats!.current.platforms.forEach(platform => {
        if (dashboard.aretoScoreFilter === platform.key)
          aretoScore = platform.aretoScore;
      });

    return PercentFormat.format(aretoScore);
  };

  const _setAretoScoreChange = useCallback(() => {
    if (dashboard.aretoScoreFilter === 'ALL') {
      const change = calculatePercentageChange(
        dashboard.accountStats!.current.aretoScore,
        dashboard.accountStats!.previous.aretoScore
      );

      setAretoScoreChange(change);
    } else {
      dashboard.accountStats!.current.platforms.forEach(platform => {
        if (dashboard.aretoScoreFilter === platform.key) {
          const previousAretoScore =
            dashboard.accountStats!.previous.platforms.find(p => {
              if (p.key === platform.key) return true;
              return false;
            })!.aretoScore;

          const change = calculatePercentageChange(
            platform.aretoScore,
            previousAretoScore
          );
          setAretoScoreChange(change);
        }
      });
    }
  }, [dashboard.accountStats, dashboard.aretoScoreFilter]);

  useEffect(() => {
    _setAretoScoreChange();
  }, [dashboard.accountStats, _setAretoScoreChange]);

  const _transformChartStats = () => {
    return dashboard
      .accountStats!.current.platforms.filter(platform => {
        if (dashboard.aretoScoreFilter === 'ALL') return true;
        else if (dashboard.aretoScoreFilter === platform.key) return true;
        return false;
      })
      .map((stat: PlatformStats, index: number) => {
        let intervals = stat.intervals;
        let dataWithConvertedIntervals;

        if (
          dashboard.dateRangeFilter.key === DatePreset.custom &&
          dashboard.dateRangeFilter.interval === HistogramInterval.Day
        ) {
          intervals = intervals.slice(0, -1);
        } else if (
          dashboard.dateRangeFilter.key !== DatePreset.custom &&
          dashboard.dateRangeFilter.interval === HistogramInterval.Day
        ) {
          intervals = intervals.slice(0, -1);
        }
        if (
          dashboard.dateRangeFilter.key === DatePreset.last24Hours ||
          dashboard.dateRangeFilter.key === DatePreset.today ||
          (dashboard.dateRangeFilter.key === DatePreset.custom &&
            dashboard.dateRangeFilter.interval === HistogramInterval.Hour)
        ) {
          dataWithConvertedIntervals = intervals.map(s => {
            const utcDate = new Date(s.key);
            const localDate = new Date(utcDate.getTime());

            return {
              x: getDateIntervalLabel(
                localDate,
                dashboard.dateRangeFilter.key === DatePreset.last24Hours
                  ? 'custom'
                  : dashboard.dateRangeFilter.interval
              ),
              y: Math.round(s.aretoScore * 100),
            };
          });
        } else {
          dataWithConvertedIntervals = intervals.map(s => {
            const utcDate = new Date(s.key);
            const timezoneOffsetInMinutes = utcDate.getTimezoneOffset();
            const timezoneOffsetInMilliseconds =
              timezoneOffsetInMinutes * 60 * 1000;
            const localDate = new Date(
              utcDate.getTime() + timezoneOffsetInMilliseconds
            );

            return {
              x: getDateIntervalLabel(
                localDate,
                dashboard.dateRangeFilter.interval
              ),
              y: Math.round(s.aretoScore * 100),
            };
          });
        }

        return {
          label: stat.key,
          borderColor: ChartColors[index],
          pointBackgroundColor: [ChartColors[index]],
          fill: true,
          data: dataWithConvertedIntervals,
        };
      });
  };

  const chartOptions: ChartConfiguration = {
    type: 'line',
    data: {
      //@ts-ignore
      datasets: _transformChartStats(),
    },
    options: {
      maintainAspectRatio: false,
      animation: false,
      scales: {
        x: {
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            font: {
              size: 10,
            },
            callback(value, index, ticks) {
              if ([0, 50, 100].includes(value as number)) return value;
              return null;
            },
          },
          afterDataLimits(axis) {
            // Adding some padding so points at 100% don't get clipped
            // Disabling this so we still get the 0,50,100 yAxis labels
            axis.max = 100;
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: context => {
              return (
                ' ' +
                PlatformTypeLabels[context.dataset.label!] +
                ': ' +
                context.parsed.y +
                '%'
              );
            },
          },
        },
      },
    },
  };

  return (
    <Card shadow="xl" radius="lg" p="xl" className={classes.aretoScoreCard}>
      <Badge
        className={
          aretoScoreChange >= 0
            ? classes.breakdownPercentPositiveChange
            : classes.breakdownPercentNegativeChange
        }
        size="lg"
      >
        {aretoScoreChange > 0 && '+'}

        {PercentFormat.format(aretoScoreChange / 100)}
      </Badge>
      <Space h="sm" />
      <Group position="apart">
        <Group>
          <NumericText size={44} weight={700}>
            {_getAretoScore()}
          </NumericText>
          <div>
            <Text size="sm" color={theme.colors.dark[2]}>
              Positive
            </Text>
            <Text size="xs" color={theme.colors.dark[2]} transform="uppercase">
              Overall Sentiment
            </Text>
          </div>
        </Group>
        <Select
          data={Object.entries(PlatformTypeLabels)
            .filter(([key, value]) => {
              if (TRACKED_PLATFORMS.includes(key) || key === 'ALL') return true;
              return false;
            })
            .map(([key, value]) => ({
              label: value,
              value: key,
            }))}
          value={dashboard.aretoScoreFilter}
          onChange={v => dispatch(setAretoScoreFilter(v!))}
        />
      </Group>
      <Space h="sm" />
      <Divider />
      <Space h="lg" />
      <Chart width="100%" height="308px" options={chartOptions} />
    </Card>
  );
};

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { PlatformLabel } from 'src/components';
import {
  PlatformTypeLabels,
  TrackedUsersStatsResponse,
  TrackedUserStats,
} from 'src/types';
import { CountFormat, PercentFormat } from 'src/utils/constants';
import { calculatePercentageChange } from 'src/utils/formulas';
import { formatRowDisplay, formatDifference } from 'src/utils/component';


const columns: GridColDef[] = [
  {
    field: 'platformUsername',
    headerName: 'Account Name'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
  },
  {
    field: 'platform',
    headerName: 'Platform'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<String>) => (
      <PlatformLabel platform={params.value} />
    ),
  },
  {
    field: 'totalComments',
    headerName: 'Comments'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    valueFormatter: (params) => formatRowDisplay(params.id, params.value)
  },
  {
    field: 'spamComments',
    headerName: 'Spam'.toLocaleUpperCase(),
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: (params) => formatRowDisplay(params.id, params.value)
  },
  {
    field: 'abusiveComments',
    headerName: 'Abuse'.toLocaleUpperCase(),
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: (params) => formatRowDisplay(params.id, params.value)
  },
  {
    field: 'sentimentScore',
    headerName: 'Postive Sentiment'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
  },
];

interface AccountsTableProps {
  accounts: TrackedUsersStatsResponse;
}

const createRowsFromAccountData = (trackedUsers: TrackedUserStats[]) => {
  return trackedUsers.map(user => {
    return {
      id: user.id,
      platformUsername: user.platformUsername,
      platform: PlatformTypeLabels[user.platform],
      sentimentScore: user.sentimentScore,
      totalComments: user.totalComments,
      spamComments: user.spamComments,
      abusiveComments: user.abusiveComments,
    };
  });
};

const calculateTotalsFromRows = (rows: any[]) => {
  return {
    sentimentScore: rows.reduce((sum, user) => sum + user.sentimentScore, 0) / rows.length,
    totalComments: rows.reduce((sum, user) => sum + user.totalComments, 0),
    spamComments: rows.reduce((sum, user) => sum + user.spamComments, 0),
    abusiveComments: rows.reduce((sum, user) => sum + user.abusiveComments, 0),
  };
};

const calculateRelativeDifference = (
  currentTotals: any,
  previousTotals: any
) => {
  return {
    sentimentScore: calculatePercentageChange(
      currentTotals.sentimentScore,
      previousTotals.sentimentScore
    ),
    totalComments: calculatePercentageChange(
      currentTotals.totalComments,
      previousTotals.totalComments
    ),
    spamComments: calculatePercentageChange(
      currentTotals.spamComments,
      previousTotals.spamComments
    ),
    abusiveComments: calculatePercentageChange(
      currentTotals.abusiveComments,
      previousTotals.abusiveComments
    ),
  };
};

export const AccountsTable = (props: AccountsTableProps) => {
  let currentUsers = createRowsFromAccountData(
    props.accounts.trackedUsers.current
  );
  const previousUsers = createRowsFromAccountData(
    props.accounts.trackedUsers.previous
  );

  const currentTotals = calculateTotalsFromRows(currentUsers);
  const previousTotals = calculateTotalsFromRows(previousUsers);
  const relativeDifference = calculateRelativeDifference(
    currentTotals,
    previousTotals
  );

  currentUsers = currentUsers.map(user => {
    return {
      ...user,
      sentimentScore: PercentFormat.format(user.sentimentScore),
    };
  });


  const overallRow = {
    id: 1,
    platformUsername: 'Overall [+/- change]',
    platform: '',
    totalComments: `${CountFormat.format(currentTotals.totalComments)} [${formatDifference(relativeDifference.totalComments)}]`,
    spamComments: `${CountFormat.format(currentTotals.spamComments)} [${formatDifference(relativeDifference.spamComments)}]`,
    abusiveComments: `${CountFormat.format(currentTotals.abusiveComments)} [${formatDifference(relativeDifference.abusiveComments)}]`,
    sentimentScore: `${PercentFormat.format(currentTotals.sentimentScore)} [${formatDifference(relativeDifference.sentimentScore)}]`,
  };

  currentUsers.push(overallRow);

  return (
    <DataGrid
      autoHeight
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'inherit',
        },
        '& .last-row': {
          borderRadius: '5px',
          backgroundColor: '#FFCB00',
          color: '#232132',
          fontWeight: 'bold',
        },
        '& .last-row:hover': {
          backgroundColor: '#FFCB00',
        },
        fontSize: 16,
      }}
      rows={currentUsers}
      columns={columns}
      getRowClassName={params => {
        const isLastRow =
          params.indexRelativeToCurrentPage === currentUsers.length - 1;

        return isLastRow ? 'last-row' : '';
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      disableRowSelectionOnClick
    />
  );
};

import { useSelector } from 'react-redux';

import { NoTrackedPlatforms } from './NoTrackedPlatforms';
import { PlatformsTable } from './PlatformsTable';
import { RootState } from 'src/redux/rootReducer';

export const Platforms = () => {
  const platforms = useSelector(
    (state: RootState) => state.dashboard.trackedPlatformStats
  );

  return (
    <>
      {platforms && !platforms.platforms.current?.length ? (
        <NoTrackedPlatforms />
      ) : (
        <PlatformsTable platforms={platforms!} />
      )}
    </>
  )
};
